<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'">
    <div>
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Orders</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px; padding-left: 5%">New Orders</v-list-item-title>
                </v-list-item-content>
              </template>
              <Orders style="max-height: 70vh; overflow-y: auto;" :admin="true" :orders="newOrders"
                :status="'Move to in Progress'" :commit="'updateOrderStatusToInProgress'" />
            </v-list-group>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px; padding-left: 5%">In Progress</v-list-item-title>
                </v-list-item-content>
              </template>
              <Orders style="max-height: 70vh; overflow-y: auto;" :admin="true" :orders="inProgressOrders"
                :status="'Move to Delivered'" :commit="'updateOrderStatusToDelivered'" />
            </v-list-group>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px; padding-left: 5%">Delivered Orders</v-list-item-title>
                </v-list-item-content>
              </template>
              <Orders style="max-height: 70vh; overflow-y: auto;" :admin="true" :orders="deliveredOrders.splice(0, 10)"
                :status="'Delivered'" :disabled="true" />
            </v-list-group>
          </v-list>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Change - Collection & Sale Info</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item style="padding-top: 2%">
            <v-card tile :style="{ backgroundColor: COLORS.PRIMARY }" width="100%">
              <div style="padding: 1%">
                <v-form>
                  <v-text-field v-model="collectionName" label="Collection Name" :rules="[rules.required]">
                  </v-text-field>
                  <v-text-field v-model.number="salePercent" type=number label="Sale in Percent">
                  </v-text-field>
                  <v-text-field v-model.number="saleBDT" type=number label="Sale In BDT">
                  </v-text-field>
                  <v-text-field v-model.number="saleIf" type=number label="Sale if purchase exceeds">
                  </v-text-field>
                  <v-textarea v-model="saleText" label="Sale Text">
                  </v-textarea>
                  <v-btn rounded dark @click.prevent="updateCollection()">
                    Save
                  </v-btn>
                </v-form>
              </div>
            </v-card>
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Add a New Product</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item style="padding-top: 2%">
            <vs-row justify="center">
              <h3 style="text-align: center">Add Details</h3>
              <v-card tile :style="{ backgroundColor: COLORS.PRIMARY }" width="100%">
                <div style="padding: 2%">
                  <v-form>
                    <v-text-field v-model="title" label="Add Title" :rules="[rules.required]"
                      placeholder="Missy"></v-text-field>
                    <v-textarea rows="3" v-model="description" label="Add Description"
                      placeholder="A perfect summer attire."></v-textarea>
                    <v-text-field v-model.number="price" type=number :rules="[rules.required]" label="Add Price"
                      placeholder="1500"></v-text-field>
                    <v-text-field v-model.number="quantity" type=number :rules="[rules.required]" label="Add Quantity"
                      placeholder="5"> </v-text-field>
                    <v-combobox dense :items="availableColors" v-model="colors" label="Add Colors" chips multiple>
                      <template slot="item" slot-scope="data">
                        <v-list-item-content>
                          <div> {{ data.item }}</div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div :style="{ width: '28px', height: '28px', backgroundColor: COLOR_MAP[data.item] }"></div>
                        </v-list-item-action>
                      </template>
                    </v-combobox>
                    <v-select dense :items="tagsList" v-model="tags" label="Add Tags" chips multiple>
                    </v-select>
                    <v-textarea v-model="sizeChart" label="Add Size Chart"></v-textarea>
                    <v-row>
                      <draggable v-model="files" class="d-flex flex-row flex-wrap"
                        :options="{ group: 'images', animation: 200 }">
                        <v-col v-for="(image, index) in files" :key="index" cols="4">
                          <v-card>
                            <ImageGallery class="mx-2" :item="image.url" />
                          </v-card>
                        </v-col>
                      </draggable>
                    </v-row>
                    <v-file-input multiple label="Select Product Images" v-model="tempFiles"
                      prepend-icon="mdi-camera"></v-file-input>
                    <v-btn rounded dark @click.prevent="addProduct()">
                      Add Product
                    </v-btn>
                  </v-form>
                </div>
              </v-card>
            </vs-row>
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Edit Existing Products</v-list-item-title>
            </v-list-item-content>
          </template>
          <div style="padding-top: 2%; max-height: 85vh; overflow-y: auto;">
            <EditProducts :products="allProducts" />
          </div>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size: 24px">Client Reviews</v-list-item-title>
            </v-list-item-content>
          </template>
          <div style="padding: 2% 0">
            <div v-if="testimonials && testimonials.length">
              <v-row>
                <v-col v-for="(image, index) in testimonials" :key="index" cols="4">
                  <v-card>
                    <v-img :src="image" class="mx-2" max-width="100%">
                      <template v-slot:placeholder>
                        <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                      </template>
                    </v-img>
                    <v-card-actions>
                      <v-btn depressed dark rounded :color="COLORS.RED"
                        @click="deleteTestimonial(image, index)">Delete</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <div v-if="newTestimonials && newTestimonials.length">
              <v-row>
                <v-col v-for="(image, index) in newTestimonials" :key="index" cols="4">
                  <v-img :src="image.url" class="mx-2" max-width="100%">
                    <template v-slot:placeholder>
                      <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-form>
            <v-file-input multiple label="Add Reviews" v-model="tempTestimonials"
              prepend-icon="mdi-camera"></v-file-input>
            <v-btn dark rounded @click.prevent="addTestimonials()">
              Save Changes
            </v-btn>
          </v-form>
        </v-list-group>
      </v-list>
    </div>
    <Alert :snackbar="alert" :text="alertMessage" :color="alertColor" @updateSnackbar="closeSnackbar" />
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import db from '../firebase/firebaseInit';
import { COLORS } from '@/plugins/branding.js';
import { AVAILABLE_COLORS, TAGS, COLOR_MAP } from '../plugins/utils';
import { toggleLoading } from '@/components/js/loading.js';
import { resizeImage } from '@/components/js/imageHelper.js';

export default {
  name: 'AdminPanel',
  components: {
    draggable,
    EditProducts: () => import('../components/EditProducts.vue'),
    Orders: () => import('../components/Orders.vue'),
    Alert: () => import('@/components/Alert.vue'),
    ImageGallery: () => import('@/components/ImageGallery.vue'),
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
    },
    COLORS,
    sizeChart: null,
    tempFiles: [],
    files: [],
    tempTestimonials: [],
    newTestimonials: [],
    viewUrl: null,
    title: null,
    description: null,
    price: null,
    quantity: null,
    colors: [],
    tags: [],
    tagsList: TAGS,
    alert: false,
    alertColor: null,
    alertMessage: null,
    availableColors: AVAILABLE_COLORS,
    COLOR_MAP
  }),
  created() {
    this.$store.dispatch('getOrders');
  },
  watch: {
    tempTestimonials(newFiles) {
      this.newTestimonials = newFiles.map(file => ({
        file,
        url: URL.createObjectURL(file)
      }));
    },
    tempFiles(newFiles) {
      this.files = newFiles.map(file => ({
        file,
        url: URL.createObjectURL(file)
      }));
    },
  },
  computed: {
    newOrders() {
      return this.$store.state.newOrders;
    },
    inProgressOrders() {
      return this.$store.state.inProgressOrders;
    },
    deliveredOrders() {
      return this.$store.state.deliveredOrders.slice(0, 10);
    },
    profileId() {
      return this.$store.state.profileId;
    },
    allProducts() {
      return this.$store.state.products;
    },
    collectionName: {
      get() {
        return this.$store.state.collectionName;
      },
      set(payload) {
        this.$store.commit('changeCollectionName', payload);
      },
    },
    testimonials: {
      get() {
        return this.$store.state.testimonials;
      },
      set(payload) {
        this.$store.commit('updateTestimonials', payload);
      },
    },
    salePercent: {
      get() {
        return this.$store.state.salePercent;
      },
      set(payload) {
        this.$store.commit('changeSalePercent', payload);
      },
    },
    saleBDT: {
      get() {
        return this.$store.state.saleBDT;
      },
      set(payload) {
        this.$store.commit('changeSaleBDT', payload);
      },
    },
    saleIf: {
      get() {
        return this.$store.state.saleIf;
      },
      set(payload) {
        this.$store.commit('changeSaleIf', payload);
      },
    },
    saleText: {
      get() {
        return this.$store.state.saleText;
      },
      set(payload) {
        this.$store.commit('changeSaleText', payload);
      },
    },
  },
  methods: {
    closeSnackbar(bool) {
      this.alert = bool;
    },
    async fileExists(docRef) {
      try {
        await docRef.getMetadata();
        return true;
      } catch (error) {
        if (error.code === 'storage/object-not-found') {
          return false;
        }
        throw error;
      }
    },
    toggleLoading(isLoading) {
      toggleLoading(this, isLoading);
    },
    async deleteTestimonial(image, index) {
      try {
        this.toggleLoading(true);
        const imageRef = firebase.storage().refFromURL(image);
        await imageRef.delete();
        const db = firebase.firestore();
        const testDb = db.collection('systemVariables').doc("Yrn11zrLHn7bqGVt7pjg");
        await testDb.update({
          testimonials: firebase.firestore.FieldValue.arrayRemove(image)
        });
        this.testimonials.splice(index, 1);
        this.alertColor = COLORS.GREEN;
        this.alertMessage = 'Review successfully deleted';
        this.alert = true;
        this.toggleLoading(false);
      } catch (err) {
        this.toggleLoading(false);
        this.alertColor = COLORS.GREEN;
        this.alertMessage = err.message;
        this.alert = true;
      }
    },
    async addTestimonials() {
      try {
        this.toggleLoading(true);
        const dataBase = db.collection('systemVariables').doc("Yrn11zrLHn7bqGVt7pjg");
        const storageRef = firebase.storage().ref();
        console.log(this.newTestimonials);
        const uploadPromises = this.newTestimonials.map(async ({ file }, index) => {
          let uniqueIndex = index;
          let docRef = storageRef.child(`documents/Testimonials/${uniqueIndex}`);
          let exists = await this.fileExists(docRef);
          while (exists) {
            uniqueIndex++;
            docRef = storageRef.child(`documents/Testimonials/${uniqueIndex}`);
            exists = await this.fileExists(docRef);
          }
          const resizedFile = await resizeImage(file);
          await docRef.put(resizedFile);
          const url = await docRef.getDownloadURL();
          this.testimonials.push(url);
        });
        await Promise.all(uploadPromises);
        await dataBase.update({
          testimonials: this.testimonials,
        });
        this.newTestimonials = [];
        this.alertColor = COLORS.GREEN;
        this.alertMessage = 'Successfully added reviews.';
        this.alert = true;
        this.toggleLoading(false);
      } catch (err) {
        this.toggleLoading(false);
        this.alertColor = COLORS.RED;
        this.alertMessage = err.message;
        this.alert = true;
      }
    },
    updateCollection() {
      try {
        this.toggleLoading(true);
        this.$store.dispatch('updateCollection');
        this.alertColor = COLORS.GREEN;
        this.alertMessage = 'The collection has now been updated';
        this.alert = true;
        this.toggleLoading(false);
      } catch (err) {
        this.toggleLoading(false);
        this.alertColor = COLORS.RED;
        this.alertMessage = err.message;
        this.alert = true;
      }
    },
    async addProduct() {
      try {
        if (
          this.title !== null &&
          this.price !== null &&
          this.quantity !== null &&
          this.files !== [] &&
          this.tags !== []
        ) {
          this.toggleLoading(true)
          const storageRef = firebase.storage().ref();
          const timeStamp = await Date.now();
          const dataBase = db.collection('products').doc();
          const imageUrls = [];
          const uploadPromises = this.files.map(async ({ file }, index) => {
            let uniqueIndex = index;
            let docRef = storageRef.child(`documents/ProductPhotos/${this.title}_${uniqueIndex}`);
            let exists = await this.fileExists(docRef);
            while (exists) {
              uniqueIndex++;
              docRef = storageRef.child(`documents/ProductPhotos/${this.title}_${uniqueIndex}`);
              exists = await this.fileExists(docRef);
            }
            const resizedFile = await resizeImage(file);
            await docRef.put(resizedFile);
            const url = await docRef.getDownloadURL();
            imageUrls.push(url);
          });
          await Promise.all(uploadPromises);
          await dataBase.set({
            productId: dataBase.id,
            title: this.title,
            description: this.description,
            price: this.price,
            quantity: this.quantity,
            colors: this.colors,
            images: imageUrls,
            tags: this.tags,
            profileId: this.profileId,
            date: timeStamp,
            sizeChart: this.sizeChart,
          });
          this.title = null;
          this.description = null;
          this.price = null;
          this.quantity = null;
          this.colors = [];
          this.tags = [];
          this.files = [];
          this.sizeChart = null;
          this.$store.dispatch('getProducts');
          this.toggleLoading(false)
          this.alertColor = COLORS.GREEN;
          this.alertMessage = 'The product has been added successfully!';
          this.alert = true;
          return;
        }
        this.toggleLoading(false)
        this.alertColor = COLORS.RED;
        this.alertMessage = 'Please fill out all the fields!';
        this.alert = true;
      } catch (err) {
        this.toggleLoading(false)
        this.alertColor = COLORS.RED;
        this.alertMessage = err.message.replace('Firebase:', '');
        this.alert = true;
      }
    },
  },
};
</script>

<style>
.image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
}
</style>
